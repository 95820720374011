<template>
  <h1 v-if="!isMobile" :data-id="DataIdElement.HEADING" class="heading-h3 mt-0 mb-16">
    {{ t('shipmentNumberInputTitle') }}
  </h1>
  <h1 v-else :data-id="DataIdElement.HEADING_MOBILE" class="heading-h5-heavy">
    {{ t('shipmentNumberInputTitle') }}
  </h1>
  <div v-if="!isMobile" :data-id="DataIdElement.TEXT" class="paragraph-large text-base-grey mb-48">
    {{ t('shipmentNumberInputText') }}
  </div>
  <div v-else :data-id="DataIdElement.TEXT_MOBILE" class="paragraph text-base-grey mb-32 mb-lg-48">
    {{ t('shipmentNumberInputText') }}
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { DataIdElement } from '~common/enums/tests.ts'
import type { HeadingProps } from '~common/types'

const { t } = useI18n()

defineProps<HeadingProps>()
</script>
